import { ThemeOptions } from '@mui/material';
import { createTheme } from '@mui/material/styles';

export const COLORS = {
  darkRed: '#B00020',
  softRed: '#DB3C3F',
  red: '#ED2537',
  midPink: '#FF5E69',
  lightPink: '#FBE7E7',
  white: '#FFFFFF',
  offWhite: '#FAFAFA',
  lightGray: '#E4E4E4',
  gray: '#9B9B9B',
  black: '#380004',
  black_87: 'rgba(0, 0, 0, 0.87)',
  black_60: 'rgba(0, 0, 0, 0.6)',
  black_38: 'rgba(0, 0, 0, 0.38)',
  blue: '#376691',
  lightBlue_25: 'rgba(196, 204, 234, 0.25)',
  lightCeleste: 'rgba(42, 183, 202, 0.1)',
};

export const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: COLORS.red,
      dark: COLORS.darkRed,
      light: COLORS.midPink,
      contrastText: COLORS.white,
    },
    secondary: {
      main: COLORS.midPink,
      dark: COLORS.darkRed,
      light: COLORS.blue,
      contrastText: COLORS.white,
    },
    info: {
      main: COLORS.black_60,
      dark: COLORS.black,
      light: COLORS.black_38,
      contrastText: COLORS.white,
    },
    text: {
      primary: COLORS.black_87,
      secondary: COLORS.black_60,
      disabled: COLORS.black_38,
    },
    background: {
      default: COLORS.white,
      paper: COLORS.white,
    },
    divider: COLORS.lightGray,
  },
  typography: {
    htmlFontSize: 11,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          fontSize: '62.5%',
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          margin: '1rem',
          borderColor: COLORS.midPink,
        },
      },
    },
    // Prevent label for radio or checkbox group becoming red in focused state
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&$focused': {
            color: 'unset',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .Mui-disabled': {
            textFillColor: COLORS.black_60,
          },
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          '& .MuiDataGrid-row:hover': {
            backgroundColor: '#FCEBEE50',
            color: COLORS.midPink,
            cursor: 'pointer',
          },
          '& .MuiDataGrid-cell:focus': {
            outline: 0,
          },
          '& .row-theme-failed': {
            color: COLORS.gray,
          },
          '& .cell-theme-failed': {
            color: COLORS.red,
          },
          '& .cell-theme-missing-info': {
            color: COLORS.gray,
          },
          fontSize: 16,
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1600,
    },
  },
} as ThemeOptions);
