import { referralIdentifierSlice } from '@modules/referralIdentifier/slice';
import { userSlice } from '@modules/user/slice';
import { configureStore, ThunkAction } from '@reduxjs/toolkit';
import { createWrapper } from 'next-redux-wrapper';
import { Action, combineReducers, Store } from 'redux';

const reducers = combineReducers({
  [userSlice.name]: userSlice.reducer,
  [referralIdentifierSlice.name]: referralIdentifierSlice.reducer,
});

export type State = ReturnType<typeof reducers>;

const makeStore = (): Store =>
  configureStore({
    reducer: reducers,
    devTools: process.env.NODE_ENV !== 'production',
  });

export type AppStore = ReturnType<typeof makeStore>;
export type AppState = ReturnType<AppStore['getState']>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, AppState, unknown, Action>;

export const wrapper = createWrapper<AppStore>(makeStore, {
  debug: process.env.NODE_ENV !== 'production',
});
