module.exports = {
  i18n: {
    locales: ['en'],
    defaultLocale: 'en',
    localeDetection: false,
  },
  localePath: './public/locales',
  react: {
    useSuspense: false,
  },
  trailingSlash: true,
};
