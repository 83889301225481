/* eslint-disable import/no-named-as-default-member */
import amplitude from 'amplitude-js';
import { debounce, DebouncedFunc } from 'lodash';
import ReactGA from 'react-ga4';

export const initAmplitude = (userId?: number): void => {
  if (typeof window !== undefined && process.env.NEXT_PUBLIC_AMPLITUDE_ENABLED === 'true') {
    if (process.env.NEXT_PUBLIC_AMPLITUDE_KEY) {
      amplitude
        .getInstance()
        .init(process.env.NEXT_PUBLIC_AMPLITUDE_KEY || '', userId as string | undefined, {
          includeReferrer: true,
          includeFbclid: true,
          includeGclid: true,
          includeUtm: true,
        });
    } else {
      throw Error('Amplitude is enabled but NEXT_PUBLIC_AMPLITUDE_KEY is not defined');
    }
  }
};

export const initGA = (): void => {
  if (typeof window !== undefined && process.env.NEXT_PUBLIC_GA_ENABLED === 'true') {
    if (process.env.NEXT_PUBLIC_GA_ID) {
      ReactGA.initialize(process.env.NEXT_PUBLIC_GA_ID);
    } else {
      throw Error('Google Analytics is enabled but NEXT_PUBLIC_GA_ID is not defined');
    }
  }
};

export const initAnalytics = (userId?: number): void => {
  initAmplitude(userId);
  initGA();
};

type GoogleEventData = {
  category?: string;
  label?: string;
  value?: number;
};

type LogEventData = GoogleEventData & Record<string, unknown>;

export const logEvent = (key: string, data?: LogEventData): void => {
  if (typeof window !== undefined) {
    if (process.env.NODE_ENV !== 'production') {
      console.log('logEvent', key, data);
    }
    // Deconstruct the 3 props that is used for GA and GTM.
    // For Amplitude can be any value in the data structure.
    const { category = '', label, value } = (data as GoogleEventData) || {};

    if (process.env.NEXT_PUBLIC_AMPLITUDE_ENABLED === 'true') {
      amplitude.getInstance().logEvent(key, data);
    }
    if (process.env.NEXT_PUBLIC_GA_ENABLED === 'true') {
      ReactGA.event({
        action: key,
        category,
        label,
        value,
      });
    }
    if (process.env.NEXT_PUBLIC_GTM_ID && process.env.NEXT_PUBLIC_GTM_ID !== 'DUMMY') {
      (
        window as Window &
          typeof globalThis & {
            dataLayer: { event: string; category: string; label?: string; value?: number }[];
          }
      ).dataLayer.push({
        event: key,
        category,
        label,
        value,
      });
    }
  }
};

// Dumb hack to prevent us firing multiple duplicate events from certain useEffect hooks
const debouncers: Record<string, DebouncedFunc<(key: string, data?: LogEventData) => void>> = {};
export const debouncedLogEvent = (key: string, data?: LogEventData): void => {
  if (!debouncers[key]) {
    debouncers[key] = debounce(logEvent, 1000, {
      leading: true,
      trailing: false,
    });
  }
  debouncers[key](key, data);
};

export const pageView = (url: string): void => {
  if (typeof window !== undefined) {
    if (process.env.NODE_ENV !== 'production') {
      console.log('pageView', url);
    }
    if (process.env.NEXT_PUBLIC_AMPLITUDE_ENABLED === 'true') {
      amplitude.getInstance().logEvent('page_view', { url });
    }
    if (process.env.NEXT_PUBLIC_GA_ENABLED === 'true') {
      ReactGA.send({ hitType: 'pageview', page: url });
    }
  }
};

export const pageViewTagManager = (url: string): void => {
  (
    window as Window & typeof globalThis & { dataLayer: { event: string; page: string }[] }
  ).dataLayer.push({
    event: 'pageview',
    page: url,
  });
};

export const setUserId = (id: number | null): void => {
  if (process.env.NEXT_PUBLIC_AMPLITUDE_ENABLED === 'true') {
    amplitude.getInstance().setUserId(id as string | null);
  }
};

export const setUserProperties = (properties: Record<string, unknown>): void => {
  if (process.env.NEXT_PUBLIC_AMPLITUDE_ENABLED === 'true') {
    amplitude.getInstance().setUserProperties(properties);
  }
};

export const initOrUpdateAmplitude = (userId: number | null): void => {
  if (amplitude.getInstance().isNewSession()) {
    setUserId(userId);
  } else {
    initAmplitude(userId || undefined);
  }
};

export const initOrUpdateAnalytics = (userId: number | null): void => {
  if (amplitude.getInstance().isNewSession()) {
    setUserId(userId);
  } else {
    initAnalytics(userId || undefined);
  }
};
