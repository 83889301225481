import { ReferralIdentifier } from '@lib/referralUtils';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { StudyIdState } from './types';

const initialState: StudyIdState = {
  savedIdentifier: null,
  updatedId: null,
  edited: false,
};

export const referralIdentifierSlice = createSlice({
  name: 'referralIdentifier',
  initialState: initialState,
  reducers: {
    initializeIdentifier(state, action: PayloadAction<ReferralIdentifier | null>) {
      state.savedIdentifier = action.payload;
      const initialIdentifier: string | null = action.payload ? action.payload.identifier : null;
      state.updatedId = initialIdentifier;
      state.edited = false;
    },
    saveIdentifier(state, action: PayloadAction<ReferralIdentifier>) {
      state.savedIdentifier = action.payload;
      state.edited = false;
    },
    setSavedIdentifier(state, action: PayloadAction<ReferralIdentifier | null>) {
      state.savedIdentifier = action.payload;
    },
    setUpdatedId(state, action: PayloadAction<string | null>) {
      state.updatedId = action.payload;
    },
    setIdEdited(state, action: PayloadAction<boolean>) {
      state.edited = action.payload;
    },
    resetIdentifier(state) {
      return initialState;
    },
  },
});

export const {
  initializeIdentifier,
  saveIdentifier,
  setSavedIdentifier,
  setUpdatedId,
  setIdEdited,
  resetIdentifier,
} = referralIdentifierSlice.actions;
