/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { CacheProvider } from '@emotion/react';
import { pageViewEvent } from '@lib/analyticsUtils';
import createEmotionCache from '@lib/createEmotionCache';
import { wrapper } from '@lib/store';
import { userSelector } from '@modules/user/slice';
import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { theme } from '@styles/theme';
import { initOrUpdateAmplitude } from 'common/src/lib/analytics';
import { ThemeProvider as CommonThemeProvider } from 'common/src/lib/themeProvider';
import { GetStaticProps } from 'next';
import { AppInitialProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { appWithTranslation, useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';

import i18nConfig from '../next-i18next.config';

const clientSideEmotionCache = createEmotionCache();

type AppProps = { emotionCache: any; Component: any };

const App: FC<AppInitialProps & AppProps> = ({
  Component,
  emotionCache = clientSideEmotionCache,
  pageProps,
}) => {
  const { t } = useTranslation('common');
  const { pathname, query } = useRouter();
  const user = useSelector(userSelector);

  useEffect(() => {
    initOrUpdateAmplitude(user ? user.accountId : null);
  }, [user]);

  useEffect(() => {
    pageViewEvent(pathname, query);
  }, [pathname, query]);

  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <title>{t('outcomes4meTitle', 'PI Portal')}</title>
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
        <link rel="icon" href="/favicon.ico" />
        <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
      </Head>
      <StyledEngineProvider injectFirst>
        <CommonThemeProvider theme={theme}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Component {...pageProps} />
          </ThemeProvider>
        </CommonThemeProvider>
      </StyledEngineProvider>
    </CacheProvider>
  );
};

export const getStaticProps: GetStaticProps = async ({ locale }) => ({
  props: {
    ...(await serverSideTranslations(
      locale || i18nConfig.i18n.defaultLocale,
      ['common'],
      // TODO: upgrade piportal packages to fix type
      i18nConfig as any,
    )),
  },
});

// TODO: upgrade piportal packages to fix type
export default wrapper.withRedux(appWithTranslation(App as any, i18nConfig as any));
