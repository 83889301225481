import { AdminUser } from '@lib/utils';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

export const userSlice = createSlice({
  name: 'user',
  initialState: null as AdminUser | null,
  reducers: {
    setUser(state, action: PayloadAction<AdminUser>) {
      return action.payload;
    },
    clearUser() {
      return null;
    },
  },
  extraReducers: {
    [HYDRATE]: (state, action: PayloadAction<{ user: AdminUser }>) => {
      return action.payload.user;
    },
  },
});

const { actions, reducer } = userSlice;
export const { setUser, clearUser } = actions;

export const userSelector = (state: { user: AdminUser | null }): AdminUser | null => state.user;

export default reducer;
